<template>
  <v-form>
    <v-layout text-center wrap d-flex justify-center>
      <v-flex pb-5 class="title-container">
        <h1 class="display-2 font-weight-bold">
          Tourist Tax Pro
          <a
            href="/TTP_Manuale_2021.pdf"
            target="_blank"
            rel="noopener noreferrer"
            class="text-decoration-none"
          >
            <v-icon color="#002762" class="fas fa-info-circle"></v-icon>
          </a>
        </h1>
      </v-flex>

      <v-flex pt-10 pb-5>
        <p subtitle-1 class="alert">
          <span>
            ATTENZIONE - Dal 1 Agosto 2023 i pernottamenti presso “ostelli della
            gioventù” sono esenti dall’imposta di soggiorno. E per le altre
            strutture il tetto massimo dell’imposta è passato da 4€ a 5€ a
            persona per pernottamento. Sono stati aggiunti agli esenti
            dall’imposta anche i docenti accompagnatori in servizio durante
            visite d’istruzione, nella misura di uno ogni quindici minori
            accompagnati e gli appartenenti alle forze dell’ordine che
            pernottano per esigenze di servizio. Il testo del regolamento è
            consultabile al seguente
            <a href="https://www.comune.bergamo.it/regolamenti?title=soggiorno"
              >link</a
            >. <br />SE PRIMA DEL 27/07/2023 HAI GIA’ INSERITO DEI PERNOTTAMENTI
            CHE RICADONO NEL PERIODO CHE VA DAL PRIMO AGOSTO, TI INVITIAMO AD
            AGGIORNARE GLI IMPORTI CLICCANDO SULLA MATITA IN CORRISPONDENZA
            DELLA RIGA DA MODIFICARE E POI SU SALVA (NELLA PAGINA
            GESTISCI/ESPORTA).
          </span>
        </p>

        <p subtitle-1 class="sub-title text-subtitle-1">
          Registra i dati dei tuoi ospiti, trasmetti i flussi turistici a
          Ross1000 e la rendicontazione dell'imposta di soggiorno al Comune di
          Bergamo.
        </p>
      </v-flex>

      <v-flex pt-10 pb-5 d-flex class="cta-ttp">
        <div>
          <router-link to="/insert" class="text-decoration-none">
            <CtaButton class="mb-5"
              >inserisci
              <v-icon class="ml-5">fas fa-plus</v-icon>
            </CtaButton>
          </router-link>
        </div>
        <div>
          <router-link to="/manage-export" class="text-decoration-none">
            <CtaButton class="mb-5"
              >gestisci/esporta<v-icon class="ml-5"
                >fas fa-list</v-icon
              ></CtaButton
            >
          </router-link>
        </div>
        <div>
          <router-link to="/show" class="text-decoration-none">
            <CtaButton>Inserisci/modifica le tue informazioni </CtaButton>
          </router-link>
        </div>
        <div class="mt-5" v-if="admin()">
          <router-link to="/create" class="text-decoration-none">
            <CtaButton>Crea una nuova struttura </CtaButton>
          </router-link>
        </div>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import CtaButton from "@/components/CtaButton.vue";
export default {
  components: {
    CtaButton
  },
  methods: {
    admin() {
      return this.$auth.user["https://www.visitbergamo.net"].roles.includes(
        "ttp_admin"
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.title-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  h1 {
    color: #002762;
  }
}

.alert {
  background-color: #fff9c4;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 1.2em;
  display: flex;
  text-align: left;
  justify-content: center;
  align-items: center;
  color: black;
  border-radius: 4px;
}

.sub-title {
  color: #333;
}

.cta-ttp {
  flex-direction: column;
}
</style>
